<template>
  <div class="d-flex" style="margin-top: 300px">
    <div style="margin: auto">
      <article>
        <h1>
          <b-icon icon="tools"> </b-icon>
          ¡Hola! ¡Nos encontramos en mantenimiento!
        </h1>
        <div>
          <p>
            Disculpa las molestias, pero estamos realizando tareas de
            mantenimiento en la web. Estaremos de vuelta en breve. Si necesitas
            hablar con nosotros de forma urgente,
            <a href="mailto:keylearningspa@gmail.com"
              >puedes enviarnos un email</a
            >.
          </p>
          <p>— El equipo</p>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: "MaintenanceView",
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
body {
  text-align: center;
  padding: 150px;
}
h1 {
  font-size: 50px;
}
body {
  font: 20px Helvetica, sans-serif;
  color: #333;
}
article {
  display: block;
  text-align: left;
  width: 800px;
  margin: 0 auto;
}
a {
  color: #f16558;
  text-decoration: none;
}
a:hover {
  color: #333;
  text-decoration: none;
}
</style>